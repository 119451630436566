import { tw } from '@/utils/tw';
import { default as BaseVideoTranscriptTheme } from 'base/components/VideoTranscript/theme';

const VideoTranscript = tw.theme({
  extend: BaseVideoTranscriptTheme,
  slots: {
    title: ['text-headline-xs', '!font-normal'],
    text: ['text-body-sm', 'md:text-body-md'],
    time: ['text-body-sm', 'md:text-body-md'],
  },
});

export default Object.assign(VideoTranscript, {});
