import { useStableProps } from '@/hooks/useStable';
import StandaloneVideoSlider from 'base/components/VideoSlider';

export const AllasStandaloneVideoSlider: typeof StandaloneVideoSlider = (props) => {
  const stableProps = useStableProps(
    {
      options: {
        $swiper: { $arrow: { className: 'text-gray-300' } },
        $header: { $arrow: { className: 'w-2 md:mt-1 ml-1' } },
      },
    },
    props,
  );

  return <StandaloneVideoSlider {...stableProps} />;
};
