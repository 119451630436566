import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { Transcription } from 'lib/data/video/types';
import { ReactNode } from 'react';
import { VideoTranscript } from './VideoTranscript';

export interface StandaloneVideoTranscriptProps extends StandaloneComponentProps<typeof VideoTranscript> {
  title?: ReactNode;
  transcription?: Transcription[];
}

export const StandaloneVideoTranscript: StandaloneComponent<StandaloneVideoTranscriptProps> = ({
  title,
  transcription,
  ...props
}) => {
  if (!transcription?.length) {
    return null;
  }

  return (
    <VideoTranscript {...props}>
      {title && <VideoTranscript.Title>{title}</VideoTranscript.Title>}

      <VideoTranscript.List>
        {transcription.map((transcript, index) => (
          <VideoTranscript.Line key={index}>
            <VideoTranscript.Time>{transcript.start}</VideoTranscript.Time>
            <VideoTranscript.Text>{transcript.text}</VideoTranscript.Text>
          </VideoTranscript.Line>
        ))}
      </VideoTranscript.List>
    </VideoTranscript>
  );
};

export default StandaloneVideoTranscript;
