/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import { getPropStylesFactory } from '@/utils/getPropStylesFactory';
import { getComponentStylesFactory } from '@/utils/getComponentStylesFactory';
import { getComponentThemeFactory } from '@/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "arrow_colors_default": "",
  "arrow_size_default": "pr-6 [&_svg]:w-5.5 [&_svg]:h-10",
  "arrow_variant_default": "cursor-pointer hidden md:flex flex-col justify-center data-[direction=next]:rotate-180",
  "content_colors_default": "",
  "content_size_default": "",
  "content_variant_default": "flex flex-col w-full",
  "group_colors_default": "",
  "group_size_default": "w-full",
  "group_variant_default": "flex flex-nowrap relative",
  "navigation_item_colors_default": "bg-gray-200 data-[active=true]:bg-gray-900",
  "navigation_item_size_default": "w-2 h-2",
  "navigation_item_variant_default": "rounded-full",
  "navigation_colors_default": "",
  "navigation_size_default": "gap-x-3 mt-6",
  "navigation_variant_default": "flex justify-center md:hidden",
  "title_colors_default": "text-black",
  "title_size_default": "text-headline-xs md:text-headline-sm pb-3 md:pb-5 pl-6 md:pl-0",
  "title_variant_default": "md:text-center",
  "colors_default": "",
  "size_default": "w-full",
  "variant_default": ""
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "SliderSwiper");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;